@import url(https://fonts.googleapis.com/css?family=Montserrat:500);

.gallery {
    max-width: 70%;
    margin: auto;
    padding-bottom: 20px;

    .gallery-item {
        margin: 0.5rem;
        box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
        overflow: hidden;
    }

    .gallery-image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 400ms ease-out;

        &:hover {
            transform: scale(1.05);
        }
    }
}

@supports (display: grid) {
    .gallery {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
    }

    .gallery-item {
        margin: 0;
    }
}