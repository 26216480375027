.container-espectaculos {
    background-color: $color-background-container;

    h1 {
        display: inline-block;
        font-family: 'Secular One', sans-serif;
        padding-top: 80px;
        padding-bottom: 6px;
        margin-left: 40%;
        color: $color-text;
        text-align: center;
        border-bottom: 1px solid $color-text;
    }

    p {
        color: $color-text;
        text-align: center;
        font-size: 1.1rem;
    }

    .videos {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: 300px;

        .videos-section1 {
            max-width: 100%;
            background-color: #493355;
            padding: 50px;
            border-radius: 40px;
            justify-content: space-around;
            align-items: center;
            margin: 50px 50px 0 50px;

            .lambe {
                border: solid 1px;
                border-radius: 50px;
                padding: 5px;
                background-color: #fff;
                transition: 0.3s;
                max-width: 30%;
                overflow: hidden;

                .video-espectaculos {
                    width: 99.9%;
                }

                &:hover {
                    transform: scale(1.02);
                    // box-shadow: 3px 3px 2px 1px #555;
                }

                a {
                    text-decoration: none;
                }

                .btn1 {
                    bottom: 0;
                    margin: 10px;
                    font-size: 1.2rem;
                }
            }

            .marioneta {
                @extend .lambe;

                .video-espectaculos {
                    width: 99.7%;
                }

                &:hover {
                    transform: scale(1.02);
                    // box-shadow: 3px 3px 2px 1px #555;

                }

                a {
                    text-decoration: none;
                }

                .btn2 {
                    bottom: 0;
                    margin: 10px;
                    font-size: 1.2rem;
                }
            }
        }

        .videos-section2 {
            width: 100%;
            margin-top: 40px;
            padding: 60px 0;
            justify-content: space-around;

            h3 {
                display: inline-block;
                padding-bottom: 5px;
                border-bottom: solid 1px #222;
                color: #222;
                text-align: center;
                margin-bottom: 40px;
            }

            .video-atelier {
                width: 35%;

                iframe {
                    width: 100%;
                    height: 400px;
                    border: solid 1px #222 ;
                    padding: 2px;
                    align-self: center;
                }
            }

            .video-lambe {
                width: 35%;

                iframe {
                    width: 100%;
                    height: 400px;
                    border: solid 1px #222 ;
                    padding: 2px;
                    align-self: center;
                }
            }
        }
    }
}
