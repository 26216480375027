@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

footer {
    background-color: $color-background-nav;
    p{
        color: #eee;
    }
}
.redes {
    display: inline-flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;

    .icon {
        position: relative;
        background: $color-text-nav;
        border-radius: 50%;
        padding: 5px;
        margin: 10px;
        width: 30px;
        height: 30px;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

        &:hover .nombre-red {
            top: -45px;
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }

        &:hover span,
        &:hover .nombre-red {
            text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
        }
    }

    .nombre-red {
        position: absolute;
        top: 0;
        font-size: 14px;
        background: #ffffff;
        color: #ffffff;
        padding: 5px 8px;
        border-radius: 5px;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);

        &::before {
            position: absolute;
            content: "";
            height: 8px;
            width: 8px;
            background: #ffffff;
            bottom: -3px;
            left: 50%;
            transform: translate(-50%) rotate(45deg);
            transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }
    }

    a {
        color: #474747;
    }

    .facebook:hover,
    .instagram:hover,
    .youtube:hover,
    .envelope:hover {
        a {
            color: white;
        }
    }

    .facebook:hover,
    .facebook:hover .nombre-red,
    .facebook:hover .nombre-red::before {
        background: #1877F2;
    }

    .instagram:hover,
    .instagram:hover .nombre-red,
    .instagram:hover .nombre-red::before {
        background: #E4405F;
    }

    .youtube:hover,
    .youtube:hover .nombre-red,
    .youtube:hover .nombre-red::before {
        background: #CD201F;
    }

    .envelope:hover,
    .envelope:hover .nombre-red,
    .envelope:hover .nombre-red::before {
        background: #c66d2e;
    }
    
}