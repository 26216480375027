.container-index {
    background-color: $color-background-container;

    .title-index{
        display: inline-block;
        max-width: 80%;
        margin-left: 10%;
        margin-bottom: 50px;
        border-bottom: solid 1px #111;

        h1 {
            display: inline-block;
            font-family: 'Secular One', sans-serif;
            color: $color-text;
            border-bottom: solid 1px $color-text;
            margin-bottom: 40px;
            padding-top: 40px;
            padding-bottom: 5px;
            font-size: 35px;
        }

        p {
            margin-left: 20px;
            margin-bottom: 50px;
            text-align: left;
            color: $color-text;
            font-size: 20px;
        }
    } 

    #btn-subir{
        width: 20%;
        margin: auto;
        color: $color-text;
        text-align: center;
        font-size: 60px;
    }

}