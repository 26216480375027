.container-agenda{
    text-align: center;
    h1{
        margin: 30px auto;
    }
    ul{
        list-style: none;
        margin: 20px auto;
        max-width: 500px;

    }
    .container-img-agenda{
        max-width: 700px;
        display: flex;
        flex-direction: column;
        margin: auto;
        img{
        }
    }
}